
.creating-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    img {
        width: 240px;
    }
    .text {
        color: #333333;
        font-size: 24px;
        line-height: 1;
        margin: 32px 0 36px;
    }
}
.bottom-btn {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
}
